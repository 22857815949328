@import url(https://fonts.googleapis.com/css2?family=Smooch+Sans:wght@100;200&family=Ubuntu:wght@300&display=swap);

body {
    background: rgb(181, 181, 181);
    background: linear-gradient(0deg, rgba(181, 181, 181, 1) 0%, rgba(102, 100, 97, 1) 100%);
    background-attachment: fixed;
    font-family: 'Smooch Sans', sans-serif;
}


.container {
    width: 90%;
    max-width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-flow: wrap;
    margin: auto;
    padding: 1rem;
}

.box {
    width: calc(33.33% - 30px);
    min-width: 150px;
    height: auto;
    margin: 15px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.grid-image {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
}

.grid-image:hover {
    filter: brightness(40%);
    transition: filter 0.3s ease;
}

.movie-poster-link {
    font-size: clamp(18px, 2.5vw, 22px);
    font-weight: bold;
    text-decoration: none;
    color: #1c5099;
    margin-top: 8px;
    text-align: center;
    word-break: break-word;
}

.poster {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-fluid {
    max-width: 360px !important;
    padding: 5px;
}

.btn-secondary {
    background-color: #1c5099;
}

.btn {
    background-color: #1c5099 !important;
}

/* IDs */
#main-poster {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer {
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

#form {
    display: flex;
    justify-content: center;
    align-items: center;
}

#header {
    text-align: center;
}

#slogan {
    color: black;
}

#home-link {
    color: #1c5099;
    font-weight: bold;
    text-decoration: none;
}

#search-box {
    text-align: center;
    font-size: 25px;
    width: 500px;
    height: 25px;
    border: none;
    border-radius: 4px;
    outline: none;
    padding: 1px;

}

#search-btn {
    color: white;
    background-color: #1c5099;
    margin-left: 2px;
    height: 28px;
    border: none;
    border-radius: 5px;

}

#search-btn:hover {
    background-color: #112f59;
    transition: 0.3s;
}

#search-btn:active {
    background-color: #276dcf;
}

.form-check {
    display: flex !important;
    justify-content: center;
    align-items: baseline;
}

.form-check-input:checked {
    background-color: #1c5099 !important;
}

.form-check-label {
    font-size: 25px;
    padding-left: 5px;
}


.posters-container {
    padding: 20px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.error-message {
    text-align: center;
    color: #dc3545;
    padding: 20px;
    font-size: 1.2rem;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/* Add media queries for different screen sizes */
@media screen and (max-width: 768px) {
    .container {
        width: 95%;
        padding: 0.5rem;
    }
    
    .box {
        width: calc(50% - 20px); /* 2 posters per row on tablets */
        margin: 10px;
    }
}

@media screen and (max-width: 480px) {
    .container {
        width: 100%;
    }
    
    .box {
        width: calc(100% - 20px); /* 1 poster per row on mobile */
        max-width: 300px; /* Maximum width for mobile posters */
    }

    .grid-image {
        max-width: 100%;
        height: auto;
    }
}